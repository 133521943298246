import { all, call, put } from 'redux-saga/effects';
import * as actions from './actions';
// import * as rootActions from '../rootActions';
import apis from '@/apis';

// 매물 재등록
export function* updateHome({ payload }) {
  try {
    yield put(actions.updateHomeLoading());

    const [
      totalCount,
      dawinCareGoods,
      quickSaleGoods,
      mainStaticBanner,
      // mainRollingBanner
    ] = yield all([
      call(apis.analysisApi.getStatAccumulate),
      call(apis.buyingApi.getDawinCareGoods, payload.dawinCareGoods),
      call(apis.buyingApi.getQuickSaleGoods, payload.quickSaleGoods),
      call(apis.targetingApi.getDynamicBannerData, 'main_static'),
      // call(apis.targetingApi.getDynamicBannerData, 'main_rolling'),
    ]);

    yield put(
      actions.updateHomeSuccess({
        totalCount,
        quickSaleGoods,
        dawinCareGoods,
        mainStaticBanner,
        //  mainRollingBanner
      })
    );
  } catch (e) {
    console.log(e);
    yield put(actions.updateHomeFailure(e.message));
  }
}
