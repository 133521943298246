import React, { useEffect } from 'react';
import { actions, selectors } from '@/data';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';

import Kakao from '@/pages/Login/components/Kakao';
import Naver from '@/pages/Login/components/Naver';
import Apple from '@/pages/Login/components/Apple';
import AppLoginCardStyle from './AppLoginCardStyle';
import constants from './constants';
import KakaoLogin from '../../views/LoginWeb/components/NormalUserView/components/KakaoLogin';
import NaverLogin from '../../views/LoginWeb/components/NormalUserView/components/NaverLogin';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const MainApp = () => {
  const dispatch = useDispatch();
  const showPleaseLoginPopup = useSelector(selectors.common.showPleaseLoginPopup);

  useEffect(() => {
    // 다이나믹링크를 타고 들어온 경우 로그인이 필요한 기능이면 "로그인이 필요하다." or "앱을 설치해달라" 는 팝업을 띄운다.
    // 이미 OnlyAppLogin 컴포넌트는 앱에서만 열리는 로그인 화면이므로 위와 같은 팝업은 닫는다.
    if (showPleaseLoginPopup) dispatch(actions.common.pleaseLoginPopup(false));
  }, [showPleaseLoginPopup]);
  console.log('ddd');
  return (
    <>
      <div className="content-slide-area">
        <div className="slide-container">
          <AppLoginCardStyle>
            <Swiper
              spaceBetween={30}
              loop={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}>
              {constants.map((data, i) => (
                <SwiperSlide key={i}>
                  <div className="slide-title" dangerouslySetInnerHTML={{ __html: data.title }}></div>
                  <div className="slide-figure">
                    <img src={data.src} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </AppLoginCardStyle>
        </div>
      </div>
      <fieldset className="submit-form module-a type-c large socail-login">
        <legend>로그인서식</legend>
        <div className="form-list">
          <div className="form-item">
            <div className="form-wrap">
              <div className="button-display module-a style-a type-d">
                <KakaoLogin isApp={true} />
                <NaverLogin isApp={true} />
                <Apple isApp={true} />
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default MainApp;
